<template>
  <div class="col-lg-6 col-md-12">
    <card class="card">
      <h4 slot="header" class="card-title">กราฟรายได้</h4>
      <div id="chart">
        <apexchart
          width="100%"
          height="350"
          ref="realtimeChart"
          type="area"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
      <!-- <div class="chart-area">
        <line-chart
          :height="250"
          style="height: 100%"
          ref="bigChart"
          chart-id="big-line-chart"
          :chart-data="bigLineChart.chartData"
          :gradient-colors="bigLineChart.gradientColors"
          :gradient-stops="bigLineChart.gradientStops"
          :extra-options="bigLineChart.extraOptions"
        >
        </line-chart>
      </div> -->
    </card>
  </div>
</template>
<script>
import LineChart from "@/components/Charts/LineChart";
import BarChart from "@/components/Charts/BarChart";
import moment from "moment";

export default {
  components: {
    LineChart,
    BarChart,
  },
  props: {
    botId: Number,
    wallet: Number,
    setting: Object,
  },

  data() {
    return {
      chipTransection: 0,
      pointColor: [],
      // bigLineChart: {
      //   allData: [[], []],
      //   labels: [],
      //   activeIndex: 0,
      //   chartData: {
      //     datasets: [{}],
      //     labels: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN"],
      //   },
      //   extraOptions: chartConfigs.chartOption,
      //   gradientColors: config.colors.emptyGradient,
      //   gradientStops: [1, 0, 0, 0],
      //   categories: [],
      // },

      chartOptions: {
        dataLabels: {
          enabled: false,
        },
        colors: ["#00d6b4"],
        fill: {
          colors: ["#00d6b4"],
        },
        stroke: {
          curve: "straight",
        },
        xaxis: {
          type: "category",
          labels: {
            show: false,
            align: "right",
            minWidth: 0,
            maxWidth: 160,
            style: {
              colors: ["#fff"],
            },
          },
        },

        yaxis: {
          show: true,
          showAlways: true,

          labels: {
            show: true,
            align: "right",
            minWidth: 0,
            maxWidth: 160,
            style: {
              colors: ["#fff"],
            },
          },
        },
        tooltip: {},
      },
      series: [
        {
          name: "รายได้",
          data: [
            {
              x: "start",
              y: 0,
            },
          ],
        },
      ],
      init_wallet: 0,
    };
  },
  mounted() {
    //this.getUserTransection();

    this.getUserBot();
    this.sockets.subscribe(`all`, (data) => {
      if (data.bot_type === this.botType) this.getUserBot();
    });

    this.sockets.subscribe(`user${this.$store.getters.getUserId}`, (data) => {
      if (data.action === "bet_result") {
        this.getUserTransection();
      }
    });

    // console.log(this.bigLineChart.allData[0]);
  },
  computed: {},
  methods: {
    getUserTransection() {
      this.$http
        .get(
          `${process.env.VUE_APP_BACKEND_API_URL}/user_bot_transaction/${this.botId}`
        )
        .then(({ data }) => {
          // console.log("getUser", data);

          let data_reverse = data.data.reverse();
          this.chipTransection = 0;

          this.series[0].data = [];
          this.series[0].data.push({
            x: "start",
            y: 0,
          });

          //---------------------------------------------------
          // this.bigLineChart.allData[0] = [];
          // this.bigLineChart.labels = [];
          // this.pointColor = [];

          // this.bigLineChart.allData[0].push(0);
          // this.bigLineChart.labels.push("");
          // this.pointColor.push("#BCBCBC");

          for (var i = 0; i < data.data.length; i++) {
            this.chipTransection =
              data_reverse[i].wallet - data_reverse[i].bot.init_wallet;

            // if (this.chipTransection > 0) this.pointColor.push("#42b883");
            // else if (this.chipTransection < 0) this.pointColor.push("#aa1100");
            // else this.pointColor.push("#BCBCBC");
            // this.bigLineChart.labels.push(
            //   "เวลา " +
            //     moment(String(data_reverse[i].createdAt)).format(
            //       "DD/MM/YYYY hh:mm"
            //     ) +
            //     "\nผลทำนาย " +
            //     data_reverse[i].user_bet +
            //     "\nผลการเล่น " +
            //     data_reverse[i].result
            // );
            // this.bigLineChart.allData[0].push(this.chipTransection);
            let label =
              "เวลา " +
              moment(String(data_reverse[i].createdAt)).format(
                "DD/MM/YYYY hh:mm"
              ) +
              "\n ผลทำนาย " +
              data_reverse[i].user_bet +
              "\n ผลการเล่น " +
              data_reverse[i].result;

            this.series[0].data.push({
              x: label,
              y: this.chipTransection,
            });
          }
          this.updateSeriesLine();
        })
        .catch((err) => {
          // console.log(err);
          return;
        });
    },
    // initBigChart(index) {
    //   let chartData = {
    //     datasets: [
    //       {
    //         fill: true,
    //         borderColor: config.colors.primary,
    //         borderWidth: 6,
    //         borderDash: [],
    //         borderDashOffset: 0.0,
    //         pointBackgroundColor: this.pointColor,
    //         pointBorderColor: "rgba(255,255,255,0)",
    //         pointHoverBackgroundColor: config.colors.primary,
    //         pointBorderWidth: 20,
    //         pointHoverRadius: 4,
    //         pointHoverBorderWidth: 15,
    //         pointRadius: 0,
    //         lineTension: 0,
    //         data: this.bigLineChart.allData[index],
    //       },
    //     ],
    //     labels: this.bigLineChart.labels,
    //   };
    //   // this.$refs.bigChart.updateGradients(chartData);
    //   this.bigLineChart.chartData = chartData;
    //   // this.bigLineChart.activeIndex = index;
    // },

    getUserBot() {
      this.$http
        .get(
          `${process.env.VUE_APP_BACKEND_API_URL}/user_bot/${this.$store.getters.getUserId}`
        )
        .then(({ data }) => {
          // console.log("user_bot", data);
          if (data.data.bot != null) {
            this.getUserTransection();
          }
        })
        .catch((err) => {
          return;
        });
    },
    updateSeriesLine() {
      this.$refs.realtimeChart.updateSeries(
        [
          {
            data: this.series[0].data,
          },
        ],
        false,
        true
      );
    },
  },

  beforeDestroy() {
    if (this.$rtl.isRTL) {
      this.i18n.locale = "en";
      this.$rtl.disableRTL();
    }
  },
};
</script>
<style>
</style>
