<template>
  <!-- <card> -->

  <div class="row text-white">
    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-10 mx-auto form p-4">
      <div class="text-center mb-2">
        <img v-bind:src="'/img/logo.png'" style="width: 180px" />
        <!-- <h1 class="display-4 py-2 text-truncate">New Bot</h1> -->
        <div class="px-5 mt-5">
          <form action="" class="justify-content-center">
            <div class="form-group">
              <label class="sr-only">Username</label>
              <input
                type="number"
                class="form-control"
                placeholder="Username"
                v-model="username"
              />
            </div>
            <div class="form-group">
              <label class="sr-only">Password</label>
              <input
                type="password"
                v-model="password"
                class="form-control"
                placeholder="Password"
              />
            </div>
            <div v-if="error" class="mb-2">
              <span class="error mb-4">*{{ errorMsg }}</span>
            </div>
            <div
              style="
                background-color: #000;
                box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-radius: 6px;
              "
            >
              <img src="/img/imba96logo.png" height="90px" />
              <div>
                <select
                  class="form-select btn btn-block btn-lg"
                  style="background-color: #000"
                  aria-label="Default select example"
                  v-model="web"
                >
                  <!-- <option selected value="1">Nextbet5G</option>
              <option value="2">UFA6811</option>
              <option value="3">UFASUPERBET</option> -->
                  <option selected value="4">Imba96</option>
                </select>
              </div>
            </div>

            <div
              :class="[
                'btn btn-block btn-login btn-lg btn-primary mt-2 text-white',
              ]"
              @click="login"
            >
              เข้าสู่ระบบ
            </div>
            <a
              :class="[
                'btn btn-block btn-login btn-sm  btn-success  text-white',
              ]"
              :href="lineHref"
              target="_blank"
            >
              <img v-bind:src="'/img/line.png'" class="logo-line mr-2" />
              <b class="text-white">ติดต่อสอบถาม @moneybot</b>
            </a>
            <!-- <div
              :class="['btn btn-block mt-4 btn-login btn-secondary btn-border']"
              @click="trial"
            >
              ทดลองเล่น
            </div> -->
          </form>
        </div>
      </div>

      <h5 class="text-center mt-5 text-bl text-white">
        ยังไม่มีบัญชี?
        <a
          class="text-white p-1"
          :href="registerHref"
          target="_blank"
          style="background-color: #133c62; border-radius: 2px"
        >
          เปิดบัญชีใหม่</a
        >
      </h5>
      <div class="text-right mr-2">
        <!-- <a href="">ลืมรหัสผ่าน</a> -->
      </div>
    </div>
  </div>

  <!-- <div style="height: 100px" />
  </card> -->
</template>
<script>
export default {
  components: {},
  computed: {
    lineHref: function () {
      return "https://lin.ee/uJtQObV";
    },
    registerHref: function () {
      return process.env.VUE_APP_REGISTER_HREF;
    },
  },
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      web: 4,
      username: "",
      password: "",
      checkbox_remember_me: false,
      error: false,
      errorMsg: "",
      loading: false,
    };
  },
  mounted() {
    if (this.$route.query.login_active) {
      // console.log(this.$route.query.login_active);
      this.error = true;
      this.errorMsg = "ทดลองเล่นไปแล้ว";
    }
    // console.log(localStorage.getItem("userInfo"));
  },
  methods: {
    getDate() {
      return (
        new Date().getMonth() +
        "/" +
        new Date().getDate() +
        "/" +
        new Date().getFullYear() +
        " " +
        new Date().getHours() +
        ":" +
        new Date().getMinutes() +
        ":" +
        new Date().getSeconds()
      );
    },
    trial() {
      const genUser = Math.random().toString(36).substring(2, 8);
      this.$http
        .post(`${process.env.VUE_APP_BACKEND_API_URL}/create_mock_user`, {
          username: genUser,
          password: "trial_",
          wallet: 20000,
        })
        .then(({ data }) => {
          //console.log(data);
          if (data.success) {
            this.$store
              .dispatch("setToken", data.data.user_id)
              .then((datas) => {
                // localStorage.setItem("trialTimeUp", this.getDate());
                // localStorage.setItem("trial_login", true);
                // localStorage.setItem("trial_active", true);
                // localStorage.removeItem("trial_login");
                // localStorage.removeItem("trial_active");
                this.$store.dispatch("updateUserInfo", data.data);
                this.$router.push("/").catch(() => {});
              });
          } else {
            this.errorMsg = data.message;
          }
        })
        .catch((err) => {
          this.errorMsg = err.response.data.message;
          return;
        });
    },
    login() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.$http
        .post(`${process.env.VUE_APP_BACKEND_API_URL}/login`, {
          username: this.username,
          password: this.password,
          web: this.web,
        })
        .then(({ data }) => {
          this.loading = false;
          if (data.success) {
            //edit "data.user_id to data.token"
            // console.log(data.data.user_id);
            this.$store
              .dispatch("setToken", data.data.user_id)
              .then((datas) => {
                //  localStorage.setItem("trialTimeUp", 'ever');

                localStorage.setItem("user_main", JSON.stringify(data.data));
                localStorage.setItem("trial_active", false);
                this.$store.dispatch("updateUserInfo", data.data);
                this.$router.push("/").catch(() => {});
              });
          } else {
            this.error = true;
            this.password = "";
            this.errorMsg = data.message;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.error = true;
          this.password = "";
          this.errorMsg = err.response.data.message;
          return;
        });
    },
  },
};
</script>
<style>
.btn-border {
  border: solid 1px rgb(255, 255, 255);
}
select {
  width: 400px;
  text-align-last: center;
}
select#gender option[value="4"] {
  background-image: url("/img/line.png/");
}
.form-control {
  height: 40px;
}
</style>
