<template>
  <div class="row">
    <div class="col-lg-12 col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 slot="header" class="card-title">ประวัติการเล่น</h4>
        </div>
        <b-container fluid class="table-container">
          <b-table
            class="table"
            id="my-table"
            :items="history"
            :per-page="perPage"
            :current-page="currentPage"
            small
          ></b-table>
        </b-container>
      </div>
      <b-pagination
        size="sm"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
export default {
  computed: {
    rows() {
      return this.history.length;
    },
  },
  mounted() {
    this.sockets.subscribe(
      `history_${this.$store.getters.getUserId}`,
      (data) => {
        if (data.history != null) {
          console.log("history", data.history);
          let datas;
          this.history = [];
          // this.history = data.data.hitory;
          datas = data.history.sort((a, b) => b.betTime - a.betTime);
          // console.log("historys", datas);
          for (var i = 0; i < datas.length; i++) {
            this.history.push({
              โต๊ะ: datas[i].tableID,
              ขอน: datas[i].gameShoe,
              รอบ: datas[i].gameRound,
              เวลา: this.showDate(datas[i].betTime),
              เล่น: datas[i].category,
              จำนวน: datas[i].stake,
              "ชนะ/แพ้": datas[i].profitLoss,
              ผลลัพธ์:
                datas[i].gameCode === 0 || datas[i].gameCode === 3
                  ? this.showResult(datas[i].gameCode, datas[i].winner)
                  : datas[i].ball1,
            });
          }
        }
      }
    );
    // this.sockets.subscribe(`user${this.$store.getters.getUserId}`, (data) => {
    //   // console.log("user_bot", data);
    //   if (data.action === "bet_result") {
    //     this.getHistory();
    //   }
    // });
  },
  data() {
    return {
      history: [],
      perPage: 20,
      currentPage: 1,
    };
  },
  methods: {
    showDate(unixTimestamp) {
      var date = new Date(unixTimestamp);
      return (
        date.getDate() +
        "/" +
        (date.getMonth() + 1) +
        "/" +
        date.getFullYear() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds()
      );
    },
    showResult(type, value) {
      if (type === 0) {
        if (value === 1) return "Banker";
        else if (value === 2) return "Player";
        else return "Tie";
      }
      if (type === 3) {
        if (value === 1) return "Tiger";
        else if (value === 2) return "Dragon";
        else return "Tie";
      }
    },
  },
};
</script>

<style lang="scss">
@media screen and (max-width: 640px) {
  .table-container table {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
  }
  .table-container table thead {
    flex: 0 0 auto;
    width: 100%;
  }
  .table-container table tbody {
    flex: 1 1 auto;
    display: block;
    width: 100%;
    overflow-y: auto;
  }

  .table-container table td {
    font-size: 0.85em;
    /* necessary to set for proper "showing row x of y" calculations if infinate scoll */
    white-space: nowrap;
    text-align: center;
    padding: 10px 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 320px) {
  .table-container table td {
    font-size: 0.5em;
    /* necessary to set for proper "showing row x of y" calculations if infinate scoll */
    white-space: nowrap;
    text-align: center;
    padding: 10px 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .table thead tr th {
    font-size: 0.6em;
  }
}

@media screen and (max-width: 280px) {
  .table-container table td {
    font-size: 0.5em;
    /* necessary to set for proper "showing row x of y" calculations if infinate scoll */
    white-space: nowrap;
    text-align: center;
    padding: 10px 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .table thead tr th {
    font-size: 0.5em;
  }
}
</style>