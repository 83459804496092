<template>
  <div>
    <div v-if="is_loading">
      <loading />
    </div>
    <div class="row">
      <div class="col-lg-6">
        <card type="user">
          <wallet @callback="loading_callback" />
          <div class="cursor" @click="trial()">
            <card
              type="user"
              :class="[!isTrial ? '' : 'bg-warning', 'card-hover']"
            >
              <div class="text-center">
                <h3 :class="['mt-3', !isTrial ? '' : 'text-white']">
                  <b>{{ !isTrial ? `ทดลองเล่น` : `กลับ user หลัก` }}</b>
                </h3>
              </div>
            </card>
          </div>
          <playlist :is_loading="is_loading" :wallet="wallet" />

          <!-- <card type="user">
            <div class="cursor" @click="next(1)">
              <card type="user" class="bg-primary">
                <div class="author">
                  <h3 class="mt-2 text-white">
                    <b>Baccarat</b>
                  </h3>
                </div>
                <p></p>
              </card>
            </div>
            <div class="cursor" @click="next(2)">
              <card type="user" class="bg-primary">
                <div class="author">
                  <h3 class="mt-2 text-white"><b>Roulette</b></h3>
                </div>
                <p></p>
              </card>
            </div>
            <div class="cursor" @click="next(3)">
              <card type="user" class="bg-primary">
                <div class="author">
                  <h3 class="mt-2 p-0 text-white"><b>Tiger Dragon</b></h3>
                </div>
                <p></p>
              </card>
            </div>
          </card>-->
        </card>
      </div>
       
      <div class="col-lg-6">
       <card type="user">
          <h5 ><b>กราฟรวม Roulette</b></h5>
          <apexchart-model
            :botId="null"
            :botType="1"
            :setting_data="null"
            :smSize="true"
            :jsonIndex="1"
            :chartSize="[100, 220]"
          />

          <p></p>
       
       
              <h5 ><b>กราฟรวม Baccarat</b></h5>
              <apexchart-model
                :botId="null"
                :botType="1"
                :betSide="11"
                :setting_data="null"
                :smSize="true"
                :jsonIndex="0"
                :chartSize="[100, 160]"
              />

              <p></p>
           
             
              <h5 ><b>กราฟรวม Tiger Dragon</b></h5>

              <apexchart-model
                :botId="null"
                :botType="3"
                :setting_data="null"
                :smSize="true"
                :jsonIndex="0"
                :chartSize="[120, 160]"
              />

              <p></p>
            </card>
       
      </div>
    </div>
  </div>
</template>
<script>
import LineChart from "@/components/Charts/LineChart";
import BarChart from "@/components/Charts/BarChart";

import TaskList from "@/pages/Dashboard/TaskList";
import UserTable from "@/pages/Dashboard/UserTable";

import Wallet from "./Wallet.vue";
import Loading from "@/components/Loading";
import ApexchartModel from "@/views/room/chart/ApexchartModel";
import BoxIcon from "@/components/Cards/BoxIcon";
import Playlist from "./Playlist.vue";
export default {
  components: {
    LineChart,
    BarChart,
    TaskList,
    UserTable,
    Wallet,
    Loading,
    ApexchartModel,
    BoxIcon,
    Playlist,
  },
  mounted() {
    this.$http
      .get(
        `${process.env.VUE_APP_BACKEND_API_URL}/user_bot/${this.$store.getters.getUserId}`
      )
      .then(({ data }) => {
        if (data.data.bot != null) {
          this.botType = data.data.bot.bot_type;
          this.next(data.data.bot.bot_type);
        }
      })
      .catch((err) => {
        return;
      });
  },
  created() {},

  data() {
    return {
      wallet: null,
      botType: null,
      is_loading: false,
      timer: 1,
    };
  },
  computed: {
    isTrial() {
      return localStorage.getItem("trial_active") != "false" ? true : false;
    },
    tempTrial() {
      return localStorage.getItem("user_trial") != null
        ? localStorage.getItem(
            `${
              JSON.parse(localStorage.getItem("user_main")).user_id
            }_user_trial`
          )
        : null;
    },
  },
  methods: {
    next(botType) {
      if (this.wallet != null)
        this.$router
          .push({
            path: `/room/${botType}`,
            params: {
              wallet: this.wallet.data,
            },
          })
          .catch(() => {});
      else {
        // this.countDownTimer();
        this.is_loading = true;
      }
    },
    loading_callback(value) {
      this.wallet = value;

      if (this.is_loading && this.botType != null) this.next(this.botType);
      this.is_loading = false;
    },

    trial() {
      if (this.isTrial) {
        this.$store
          .dispatch(
            "setToken",
            JSON.parse(localStorage.getItem("user_main")).user_id
          )
          .then(() => {
            this.$store.dispatch(
              "updateUserInfo",
              JSON.parse(localStorage.getItem("user_main"))
            );

            localStorage.setItem("trial_active", false);
            window.location.reload();
          });
      } else {
        if (this.tempTrial != null) {
          this.$store
            .dispatch(
              "setToken",
              JSON.parse(localStorage.getItem("user_trial")).user_id
            )
            .then(() => {
              this.$store.dispatch(
                "updateUserInfo",
                JSON.parse(localStorage.getItem("user_trial"))
              );
              localStorage.setItem("trial_active", true);
              window.location.reload();
            });
        } else {
          const genUser = Math.random().toString(36).substring(2, 8);
          this.$http
            .post(`${process.env.VUE_APP_BACKEND_API_URL}/create_mock_user`, {
              username: genUser,
              password: "trial_",
              wallet: 20000,
            })
            .then(({ data }) => {
              if (data.success) {
                localStorage.setItem("user_trial", JSON.stringify(data.data));
                localStorage.setItem(
                  `${
                    JSON.parse(localStorage.getItem("user_main")).user_id
                  }_user_trial`,
                  true
                );
                this.$store.dispatch("setToken", data.data.user_id).then(() => {
                  this.$store.dispatch("updateUserInfo", data.data);
                  localStorage.setItem("trial_active", true);
                  window.location.reload();
                });
              } else {
                this.errorMsg = data.message;
              }
            })
            .catch((err) => {
              this.errorMsg = err.response.data.message;
              return;
            });
        }
      }
    },
  },

  beforeDestroy() {
    if (this.$rtl.isRTL) {
      this.i18n.locale = "en";
      this.$rtl.disableRTL();
    }
  },
};
</script>
<style>
.cursor {
  cursor: pointer;
}
</style>
